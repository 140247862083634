const inputs = [
  {
    label: 'email',
    type: 'email',
    required: true,
  },
  {
    label: 'password',
    type: 'password',
    required: true,
  },
]

export default inputs
